import { getCloudAssets } from "~~/utils"

// icon dict
export const groupLinkTypeImg = {
  [GroupLinkType.LINE]: getCloudAssets(`/icons/common/logo_line.png`),
  [GroupLinkType.FB1]: getCloudAssets(`/icons/common/facebook-icon.png`),
  [GroupLinkType.FB2]: getCloudAssets(`/icons/common/facebook-icon.png`),
  [GroupLinkType.KAKAO]: getCloudAssets(`/icons/common/kakao-icon.png`),
  [GroupLinkType.TWITTER]: getCloudAssets(`/icons/common/twitter-icon.png`),
  [GroupLinkType.ZALO]: getCloudAssets(`/icons/common/zalo-icon.png`)
}

export const joinDescList = computed(() => [
  useTranslateI18n(`download.thanks.list[0]`),
  useTranslateI18n(`download.thanks.list[1]`),
  useTranslateI18n(`download.thanks.list[2]`)
])
